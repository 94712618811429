// @ts-strict-ignore
import { useEffect, useState } from "react"

import { EmberApiError } from "api/errors"

import { Unsubscribable } from "types/observable"

export interface PollingConfig {
  // How many times to poll. If we poll this many times without success or
  // error, we give up.
  maxPollingCalls?: number

  // We'll silently ignore up to this number of errors while polling. The idea
  // is that we're robust enough to keep polling if the user has temporary
  // connectivity problems
  maxPollingErrors?: number

  // How long to wait between polls, in milliseconds
  interval?: number
}

export interface PollFunctionParams<PayloadType> {
  onSuccess: (payload: PayloadType) => void
  onError: (error: EmberApiError) => void
}

interface usePollingParams<PayloadType> {
  config?: PollingConfig
  poll: (params: PollFunctionParams<PayloadType>) => Unsubscribable
  isComplete: (payload: PayloadType) => boolean
  onSuccess: (payload: PayloadType) => void
  onError: (error: EmberApiError) => void
}

interface Pollable {
  start: () => void
  isPolling: () => boolean
  hasStarted: () => boolean
}

// raised when we've polled `maxPollingCalls` times, and `isComplete` never
// returned true
export const POLL_EXHAUSTED_ERROR = "PollExhausted"

/**
 * This utility is for when we want to poll the backend at regular intervals, up to a maximum number
 * of times, waiting for a specific value to appear, at which point we stop polling. For instance
 * this is what we use when waiting for the backend to confirm that a Stripe payment has gone
 * through: we repeatedly fetch the status of the order we just placed, until its `status` indicates
 * that the payment if confirmed.
 *
 * This is designed to wrap around one of the API functions (functions found under `src/api/` and
 * whose name starts with "fetch"). The type of object that the function's `onSuccess` handler
 * receives is the `PayloadType` for this utility. The `poll` function repeatedly calls the fetch
 * function, and after eacg call the `isComplete` function inspects the payload to decide whether we
 * can stop polling. The `onSuccess` returns that payload.
 *
 *
 * Suppose you wanted e.g. to call `fetchOrder` repeatedly until the order's payment has resolved.
 * The `onSuccess` handler for `fetchOrder` takes an `Order` object, so that's our `PayloadType`.
 *
 * The polling would be set up like this:
 *
 *     usePolling<Order>({
 *       // for every poll, we fetch the order
 *       poll: ({ onSuccess, onError }) => {
 *         fetchOrder({ orderId: 123, onSuccess, onError })
 *       },
 *
 *       // stop as soon as all payments have resolved
 *       isComplete: (order: Order) => {
 *         order.payments.every(
 *           (p) => p.status != PaymentStatus.PENDING_CONFIRMATION
 *         )
 *       },
 *
 *       // these handlers are called when we stop polling
 *       onSuccess: (order: Order) => {
 *         celebrate(order)
 *       },
 *       onError: (error: EmberApiError) => {
 *         apologise(error)
 *       },
 *     })
 *
 */
export function usePolling<PayloadType>({
  config = {},
  poll,
  isComplete,
  onSuccess: onPollingSuccess,
  onError: onPollingError,
}: usePollingParams<PayloadType>): Pollable {
  const [pollsRemaining, setPollsRemaining] = useState<number>()
  const [numErrors, setNumErrors] = useState<number>()
  const { maxPollingCalls = 60, maxPollingErrors = 2, interval = 1000 } = config

  useEffect(() => {
    if (!pollsRemaining) {
      return
    }

    let subscribed = true
    const sleepThenPollAgain = () => {
      setTimeout(() => {
        if (subscribed) {
          // NB if `subscribed` is not true, it means this effect cleanup
          // has run while we were sleeping. This happens if e.g. the
          // component that uses the polling was removed from the DOM.
          setPollsRemaining(pollsRemaining - 1)
        }
      }, interval)
    }

    const sub = poll({
      onSuccess: (payload: PayloadType) => {
        if (isComplete(payload)) {
          // This means we're done, the polling has achieved its goal
          setPollsRemaining(0)
          onPollingSuccess(payload)
        } else if (pollsRemaining > 1) {
          // Not done yet. Keep polling.
          sleepThenPollAgain()
        } else {
          // We've used up all our polling attemps, we've timed out
          setPollsRemaining(0)
          onPollingError({
            type: POLL_EXHAUSTED_ERROR,
            message: "poll exhausted",
          })
        }
      },
      onError: (error: EmberApiError) => {
        if (numErrors < maxPollingErrors && isWorthRetrying(error)) {
          setNumErrors(numErrors + 1)
          sleepThenPollAgain()
        } else {
          setPollsRemaining(0)
          onPollingError(error)
        }
      },
    })

    return () => {
      subscribed = false
      if (sub?.unsubscribe) {
        sub.unsubscribe()
      }
    }
  }, [pollsRemaining])

  return {
    start: () => {
      setNumErrors(0)
      setPollsRemaining(maxPollingCalls)
    },
    isPolling: () => !!pollsRemaining,
    hasStarted: () => pollsRemaining != null,
  }
}

function isWorthRetrying(error: EmberApiError): boolean {
  // We assume that 4xx errors won't go away if we just retry, as opposed to
  // 5xx errors, or network errors
  return (
    !error.httpStatusCode ||
    error.httpStatusCode < 400 ||
    error.httpStatusCode >= 500
  )
}
