import { EmberApiError, parseError } from "api/errors"

import { Unsubscribable } from "types/observable"

import { fetchFromAPIBase } from "utils/fetch-utils"

interface reallocateVehicleParams {
  start: string
  end: string
  minimumNumberOfVehicles: number
  onSuccess: (runId: string) => void
  onError: (error: EmberApiError) => void
}

export function reallocateVehicles({
  start,
  end,
  minimumNumberOfVehicles,
  onSuccess,
  onError,
}: reallocateVehicleParams): Unsubscribable {
  return fetchFromAPIBase({
    path: "/v1/vehicles/allocation/",
    method: "POST",
    authRequired: true,
    body: {
      start: start,
      end: end,
      minimumNumberOfVehicles: minimumNumberOfVehicles,
    },
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response.id)
    } else {
      onError(parseError(response))
    }
  })
}
