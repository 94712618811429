// @ts-strict-ignore
import React, { useState } from "react"

import { Form, Formik } from "formik"
import moment from "moment"
import styled from "styled-components"
import * as Yup from "yup"

import {
  Alert,
  Button,
  Grid,
  Stack,
  Text,
  Tooltip,
} from "@kiwicom/orbit-components"
import { CheckCircle, InformationCircle } from "@kiwicom/orbit-components/icons"
import AlertIcon from "@kiwicom/orbit-components/lib/icons/Alert"

import { EmberApiError } from "api/errors"
import { reallocateVehicles } from "api/vehicle-assignment"

import { EmberCardSection } from "components/generic/ember-card"
import ValidatedInputField from "components/validated-input-field"

import { PollingConfig, usePolling } from "logic/polling"

import { getDefaultTimezone } from "utils/date-utils"
import { fetchFromAPIBase } from "utils/fetch-utils"

const IconWrapper = styled.div`
  position: relative;
  bottom: 2px;
`

export interface VehicleAssignmentRun {
  start: Date
  end: Date
  id: string
  isComplete: boolean
  status: "requested" | "cancelled" | "finished"
}

// This constant is a function so that it can be easily mocked in tests
export const getPollingConfig = (): PollingConfig => ({
  maxPollingCalls: 300,
  interval: 5000,
})

export const VehicleAssignmentForm = () => {
  const [runId, setRunId] = useState<string>(null)
  const [isWaiting, setWaiting] = useState<boolean>(false)
  const [outcome, setOutcome] = useState<
    | null
    | "requested"
    | "cancelled"
    | "finished"
    | "error"
    | "failed_due_to_unassigned_trips"
    | "failed_due_to_concurrent_changes"
    | "failed_because_changes_apply_too_soon"
  >(null)

  const polling = usePolling<VehicleAssignmentRun>({
    config: getPollingConfig(),
    poll: ({ onSuccess, onError }) =>
      fetchFromAPIBase({
        path: `/v1/vehicles/allocation/?run_id=${runId}`,
        method: "GET",
      }).subscribe((response) => {
        if (response && !response.error) {
          onSuccess(response)
        } else {
          onError(response)
        }
      }),
    isComplete: (details: VehicleAssignmentRun) =>
      details.status != "requested",
    onSuccess: (response) => {
      // Now we're truly done
      setWaiting(false)
      setOutcome(response.status)
    },
    onError: (_: EmberApiError) => {
      setWaiting(false)
      setOutcome("error")
    },
  })

  return (
    <EmberCardSection
      title={
        <Stack direction="row" spacing="XXSmall" align="center">
          <Text weight="medium">Assign Vehicles</Text>
          <Tooltip
            content={
              <Text>
                Replace the assignment of vehicles to trips during the period
                selected. The period is usually set to start at 2am on the day
                of the first assignment, and set to end at midday on the final
                day of assignment. The final day is typically 7 days after the
                first day.
              </Text>
            }
          >
            <IconWrapper>
              <InformationCircle color="info" size="small" />
            </IconWrapper>
          </Tooltip>
        </Stack>
      }
    >
      <Formik
        initialValues={{
          start: moment()
            .tz(getDefaultTimezone())
            .add(1, "day")
            .set({ hour: 2, minute: 0 })
            .format("YYYY-MM-DDTHH:mm"),
          end: moment()
            .tz(getDefaultTimezone())
            .add(8, "days")
            .set({ hour: 12, minute: 0 })
            .format("YYYY-MM-DDTHH:mm"),
          minimumNumberOfVehicles: 50,
        }}
        enableReinitialize
        validationSchema={Yup.object({
          start: Yup.date()
            .typeError("Use YYYY-MM-DDTHH:mm format")
            .required("Enter the time from which to re-allocate trips"),
          end: Yup.date()
            .typeError("Use YYYY-MM-DDTHH:mm format")
            .required("Enter the time until which to re-allocate trips"),
        })}
        onSubmit={(values) => {
          setWaiting(true)
          setOutcome(null)
          const { start, end, minimumNumberOfVehicles } = values
          reallocateVehicles({
            start: start,
            end: end,
            minimumNumberOfVehicles: minimumNumberOfVehicles,
            onSuccess: (runId: string) => {
              setRunId(runId)
              polling.start()
            },
            onError: () => {
              setWaiting(false)
              setOutcome("error")
            },
          })
        }}
      >
        {(props) => (
          <Form>
            <Stack direction="column" spacing="large" spaceAfter="medium">
              <Grid
                rowGap="30px"
                columnGap="16px"
                width="100%"
                tablet={{ columns: "1fr 1fr 1fr 200px" }}
              >
                <ValidatedInputField
                  name="start"
                  label="From"
                  inlineLabel
                  value={props.values.start}
                  dataTest="vehicle-assignment-start"
                />
                <ValidatedInputField
                  name="end"
                  label="To"
                  inlineLabel
                  value={props.values.end}
                  dataTest="vehicle-assignment-end"
                />
                <ValidatedInputField
                  name="minimumNumberOfVehicles"
                  label="Vehicles"
                  inlineLabel
                  value={props.values.minimumNumberOfVehicles}
                  dataTest="vehicle-assignment-minimum-number-of-vehicles"
                />
                <Button
                  disabled={isWaiting}
                  loading={isWaiting}
                  fullWidth={true}
                  submit={true}
                  dataTest="vehicle-assignment-submit"
                >
                  Reassign vehicles
                </Button>
              </Grid>
              {outcome == "finished" && (
                <Alert
                  type="success"
                  icon={<CheckCircle />}
                  title={"A new vehicle assignment was applied"}
                  closable
                  onClose={() => setOutcome(null)}
                />
              )}
              {![null, "finished", "requested"].includes(outcome) && (
                <Alert
                  type="critical"
                  icon={<AlertIcon />}
                  title={`There was an error with the request to reassign vehicles: ${outcome.replace(
                    /_/g,
                    "_"
                  )}`}
                  closable
                  onClose={() => setOutcome(null)}
                />
              )}
            </Stack>
          </Form>
        )}
      </Formik>
    </EmberCardSection>
  )
}
