// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import { Form, Formik, useFormikContext } from "formik"
import moment from "moment-timezone"
import styled from "styled-components"
import * as Yup from "yup"

import {
  Alert,
  Button,
  Grid,
  InputFile,
  Stack,
  Text,
  TextLink,
  Tooltip,
} from "@kiwicom/orbit-components"
import {
  Alert as AlertIcon,
  CheckCircle,
  InformationCircle,
} from "@kiwicom/orbit-components/icons"

import { parseErrorMessage } from "api/errors"

import Container from "components/container"
import {
  EmberCard,
  EmberCardGroup,
  EmberCardSection,
} from "components/generic/ember-card"
import { MultilineText } from "components/generic/multiline-text"
import { AdminLayout } from "components/layout-custom"
import ValidatedInputField from "components/validated-input-field"

import { getDefaultTimezone } from "utils/date-utils"
import { fetchWithAuth } from "utils/fetch-utils"

import { VehicleAssignmentForm } from "../../../../components/service-management/vehicle-assignment"

const apiBaseUrl = process.env.GATSBY_API_BASE_URL

const IconWrapper = styled.div`
  position: relative;
  bottom: 2px;
`

const Page = () => (
  <AdminLayout title="Actions">
    <Container>
      <Stack spacing="XLarge">
        <EmberCardGroup sectionTitle="Timetable Management">
          <EmberCard>
            <TimetableUpload />
            <VehicleAssignmentForm />
            <TimetableActionsForm type={ActionType.TimetableGeneration} />
            <TimetableActionsForm type={ActionType.TimetablePurge} />
          </EmberCard>
        </EmberCardGroup>
        <EmberCardGroup sectionTitle="Shift Management">
          <EmberCard>
            <TimetableActionsForm type={ActionType.ShiftGeneration} />
            <TimetableActionsForm type={ActionType.ShiftPurge} />
            <EmberCardSection title="Bulk Shift Editor">
              <Text>
                The bulk shift editor should only be used following training by
                the tech team. If you have had this training then you can go
                ahead and{" "}
                <TextLink href="./bulk-edit-shifts/">edit shifts</TextLink>.
              </Text>
            </EmberCardSection>
          </EmberCard>
        </EmberCardGroup>
      </Stack>
    </Container>
  </AdminLayout>
)

const TimetableUpload = () => {
  const [isSubmitting, setSubmitting] = useState(false)
  const [file, setFile] = useState(null)
  const [status, setStatus] = useState(null)

  function onClick(ignoreWarnings = false) {
    const queryString = ignoreWarnings == true ? "?ignore_warnings=true" : ""
    setSubmitting(true)
    setStatus(null)
    const formData = new FormData()
    formData.append("file", file)
    fetchWithAuth(
      `${apiBaseUrl}/v1/timetables/upload/${file.name}/${queryString}`,
      "POST",
      formData,
      false,
      null,
      false
    ).subscribe((response) => {
      if (response && !response.error) {
        setStatus({
          success: true,
        })
      } else {
        // TODO: Remove string handling once back-end returns proper JSON
        const errorString = parseErrorMessage(response)
        const cleanString = errorString
          .replaceAll("'", '"')
          .replaceAll("\\n\\n", "\\n")
        let errorJSON: object
        try {
          errorJSON = JSON.parse(cleanString)
        } catch {
          errorJSON = null
        }
        setStatus({
          error: true,
          message: errorString,
          errorJSON: errorJSON,
        })
      }
      setSubmitting(false)
    })
  }

  return (
    <EmberCardSection
      title={
        <Stack direction="row" spacing="XXSmall" align="center">
          <Text weight="medium">Upload New Timetable</Text>
          <Tooltip
            content={
              <Text>
                Upload a CSV file describing a new version of the timetable for
                a route. Please read the{" "}
                <TextLink
                  href="https://www.notion.so/goember/Timetable-Update-eb8285b4416945aa8c1bdcf1aa6aeeab"
                  external={true}
                >
                  documentation
                </TextLink>
                .
              </Text>
            }
          >
            <IconWrapper>
              <InformationCircle color="info" size="small" />
            </IconWrapper>
          </Tooltip>
        </Stack>
      }
    >
      <Stack direction="column" spacing="large" spaceAfter="medium">
        <Grid
          rowGap="30px"
          columnGap="16px"
          width="100%"
          tablet={{ columns: "1fr 200px" }}
        >
          <InputFile
            buttonLabel="Upload Timetable"
            onRemoveFile={() => setFile(null)}
            onChange={(event) =>
              setFile((event.target as HTMLInputElement).files[0])
            }
            fileName={file ? file.name : ""}
          ></InputFile>
          <Button
            disabled={isSubmitting || !file}
            loading={isSubmitting}
            fullWidth={true}
            submit={true}
            onClick={() => onClick()}
          >
            Submit Timetable
          </Button>
        </Grid>
        {status?.success && (
          <Alert
            type="success"
            icon={<CheckCircle />}
            title={
              "The request to upload the timetable went through successfully"
            }
            closable
            onClose={() => setStatus(null)}
          >
            You should still check that it looks as expected
          </Alert>
        )}
        {status?.error && (
          <Alert
            type="critical"
            icon={<AlertIcon />}
            title={
              "There were warnings or errors when trying to upload the timetable"
            }
            closable
            onClose={() => setStatus(null)}
          >
            {status.errorJSON && Array.isArray(status.errorJSON) ? (
              <Stack spaceAfter="small">
                <Text>The warning messages returned were as follows.</Text>
                {status.errorJSON.map((messageItem: string, index: number) => (
                  <MultilineText key={index}>{messageItem}</MultilineText>
                ))}
                <Button
                  type="critical"
                  size="small"
                  disabled={isSubmitting || !file}
                  loading={isSubmitting}
                  submit={true}
                  onClick={() => onClick(true)}
                >
                  Upload With Warnings Ignored
                </Button>
              </Stack>
            ) : (
              <MultilineText>The message was: "{status.message}"</MultilineText>
            )}
          </Alert>
        )}
      </Stack>
    </EmberCardSection>
  )
}

enum ActionType {
  TimetableGeneration = "timetable.generation",
  TimetablePurge = "timetable.purge",
  ShiftGeneration = "shift.generation",
  ShiftPurge = "shift.purge",
}

interface TimetableActionsFormOptions {
  type: ActionType
}

const TimetableActionsForm = ({ type }: TimetableActionsFormOptions) => {
  let actionTextTitle: string
  let actionText: string
  let expectedOutcome: string
  let helpText: React.ReactNode

  switch (type as ActionType) {
    case ActionType.TimetableGeneration:
      actionTextTitle = "Generate Trips"
      actionText = "generate trips"
      expectedOutcome = "the expected trips actually created"
      helpText = (
        <Stack>
          <Text>
            Create new trips for the selected date using the timetable patterns
            in the system. If a pattern has already created trips for a date,
            they will not be replaced but any trips from new patterns will be
            created.
          </Text>
          <Text>
            This is the first step for making tickets available for sale, but
            the booking cut off date in the front-end app will also need to be
            updated.
          </Text>
        </Stack>
      )
      break
    case ActionType.TimetablePurge.toString():
      actionTextTitle = "Delete Trips"
      actionText = "delete trips"
      expectedOutcome = "the expected trips actually deleted"
      helpText = (
        <Text>
          Delete trips for the selected dates. Deletion will fail if there are
          already tickets booked for a ticket on a date within the range (but
          the error will be at a later stage, after the request for deletion has
          succeeded).
        </Text>
      )
      break
    case ActionType.ShiftGeneration.toString():
      actionTextTitle = "Generate Shifts"
      actionText = "generate shifts"
      expectedOutcome = "the expected trips actually created"
      helpText = (
        <Text>
          Create shifts for the selected dates. For any date in the submitted
          range, shifts will be created cover all the trips starting at 4am util
          4am the next day.
        </Text>
      )
      break
    default:
      throw new Error(
        `Unrecognized action type passed through to timetable actions form. Action type was ${
          type as ActionType
        }`
      )
    case ActionType.ShiftPurge.toString():
      actionTextTitle = "Purge Shifts"
      actionText = "purge shifts"
      expectedOutcome = "the expected shifts actually deleted"
      helpText = (
        <Text>
          Delete shifts for the selected dates. For any date in the submitted
          range, all the shifts will be deleted starting at 4am util 4am the
          next day.
        </Text>
      )
      break
  }

  return (
    <EmberCardSection
      title={
        <Stack direction="row" spacing="XXSmall" align="center">
          <Text weight="medium">{actionTextTitle}</Text>
          <Tooltip content={helpText}>
            <IconWrapper>
              <InformationCircle color="info" size="small" />
            </IconWrapper>
          </Tooltip>
        </Stack>
      }
    >
      <Formik
        initialValues={{
          startDate: moment().tz(getDefaultTimezone()).format("YYYY-MM-DD"),
          endDate: moment().tz(getDefaultTimezone()).format("YYYY-MM-DD"),
        }}
        enableReinitialize
        validationSchema={Yup.object({
          startDate: Yup.date()
            .typeError("Use YYYY-MM-DD format")
            .required("Enter a start date"),
          endDate: Yup.date()
            .typeError("Use YYYY-MM-DD format")
            .required("Enter an end date"),
        })}
        onSubmit={(values, { setSubmitting, setStatus, setTouched }) => {
          setSubmitting(true)
          setStatus(null)
          setTouched({})
          const { startDate, endDate } = values
          fetchWithAuth(`${apiBaseUrl}/v1/timetables/`, "POST", {
            type: type.toString(),
            start_date: startDate,
            end_date: endDate,
          }).subscribe((response) => {
            if (response && !response.error) {
              setStatus({
                success: true,
              })
            } else {
              setStatus({
                error: true,
                message: parseErrorMessage(response),
              })
            }
            setSubmitting(false)
          })
        }}
      >
        {(props) => (
          <Form>
            <Stack direction="column" spacing="large" spaceAfter="medium">
              <ClearMessages />
              <Grid
                rowGap="30px"
                columnGap="16px"
                width="100%"
                tablet={{ columns: "1fr 1fr 200px" }}
              >
                <ValidatedInputField
                  name="startDate"
                  label="From"
                  inlineLabel
                  value={props.values.startDate}
                />
                <ValidatedInputField
                  name="endDate"
                  label="To"
                  inlineLabel
                  value={props.values.endDate}
                />
                <Button
                  disabled={props.isSubmitting}
                  loading={props.isSubmitting}
                  fullWidth={true}
                  submit={true}
                >
                  {actionTextTitle}
                </Button>
              </Grid>
              {props.status?.success && (
                <Alert
                  type="success"
                  icon={<CheckCircle />}
                  title={`The request to ${actionText} went through successfully`}
                  closable
                  onClose={() => props.setStatus(null)}
                >
                  You should still check that {expectedOutcome}
                </Alert>
              )}
              {props.status?.error && (
                <Alert
                  type="critical"
                  icon={<AlertIcon />}
                  title={`There was an error with the request to ${actionText}`}
                  closable
                  onClose={() => props.setStatus(null)}
                >
                  The error message was "{props.status.message}"
                </Alert>
              )}
            </Stack>
          </Form>
        )}
      </Formik>
    </EmberCardSection>
  )
}

const ClearMessages = () => {
  const { touched, setStatus } = useFormikContext()

  useEffect(() => {
    setStatus(null)
  }, [touched])

  return null
}

export default Page
